export const base64ToFile = (base64: string, cb: (file: any) => void) => {
	try {
		fetch(base64)
			.then((r) => r.blob())
			.then((blb) => {
				const file = new File([blb], `${randomId()}.png`, {
					type: 'image/png',
				});
				cb(file);
			});
	} catch (error) {
		cb(null);
	}
};
export const randomId = () => {
	return Math.random().toString(36).substr(2, 9);
};
export const getBase64 = (img: File, callback: (res: string) => void): void => {
	const reader: any = new FileReader();
	reader.readAsDataURL(img);
	reader?.addEventListener('load', () => callback(reader.result));
};
export const toCapitalize = (str: string) => {
	return str?.charAt(0).toUpperCase() + str.slice(1);
};
type KEYS = 'IS_FRONT_CAMERA';
export const setLocalData = (key: KEYS, value: any) => {
	try {
		localStorage.setItem(key, JSON.stringify(value));
	} catch (error) {}
};
export const getLocalData = (key: KEYS) => {
	try {
		const value = localStorage.getItem(key);
		return value ? JSON.parse(value) : null;
	} catch (error) {
		return null;
	}
};
export async function hasCameraAccess(): Promise<boolean> {
	if (!navigator?.mediaDevices || !navigator?.mediaDevices?.getUserMedia) {
		return false;
	}
	try {
		const stream = await navigator?.mediaDevices?.getUserMedia({ video: true });
		stream.getTracks().forEach((track) => track.stop());
		return true;
	} catch (error) {
		return false;
	}
}
export async function promptForCameraAccess(): Promise<MediaStream> {
	try {
		// Try to get camera access
		const stream = await navigator.mediaDevices.getUserMedia({ video: true });
		// Return the stream if access is granted
		return stream;
	} catch (error) {
		// Handle the error if access is denied
		console.error('Failed to access camera:', error);
		throw error;
	}
}
