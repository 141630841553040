import { Button, Empty } from 'antd';

import { IOption } from '../models';
import React from 'react';

interface IFProps {
	data?: IOption[];
	onRegenerate?: () => void;
}
const DetailsPage: React.FC<IFProps> = ({ data, onRegenerate }) => {
	return (
		<div className='h-screen overflow-y-scroll min-h-min w-full bg-white container relative'>
			{data?.length > 0 && (
				<ul className='px-6 py-10'>
					{data?.map((item) => (
						<li className='py-3'>
							<span className='text-gray-600 text-sm block mb-1'>
								{item?.key}
							</span>
							<span className='text-gray-600 text-sm block'>{item?.value}</span>
						</li>
					))}
				</ul>
			)}
			{data?.length <= 0 && (
				<div className='flex justify-center items-center h-full w-full'>
					<Empty></Empty>
				</div>
			)}
			<div className='fixed left-0 bottom-10 flex justify-center w-full'>
				<Button
					className='bg-blue-700 text-white mt-auto '
					size='middle'
					onClick={onRegenerate}
				>
					Recognize another business card
				</Button>
			</div>
		</div>
	);
};
export default DetailsPage;
