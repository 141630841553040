export const ApiServices = {
	uploadDocs: async (file: any) => {
		try {
			const response = await fetch(
				'https://api.textin.com/robot/v1.0/api/business_card',
				{
					method: 'POST',
					body: '',
				}
			);
			return response.json();
		} catch (error) {}
	},
};
export const uploadDocs = async (file: any, cb: (res) => void) => {
	var reader = new FileReader();
	reader.readAsArrayBuffer(file);
	reader.onload = function (e) {
		var fileData = this.result;
		var xhr = new XMLHttpRequest();
		var appId = 'f014807fcf2d99d40261341b33663b7a';
		var secretCode = '43e34ce644fddb9eb17051c5bc683844';
		var url = 'https://api.textin.com/robot/v1.0/api/business_card';
		xhr.open('POST', url);
		xhr.setRequestHeader('x-ti-app-id', appId);
		xhr.setRequestHeader('x-ti-secret-code', secretCode);
		xhr.onreadystatechange = function () {
			if (xhr.readyState === 4) {
				xhr.onreadystatechange = null;
				var response = xhr.response;
				var obj: any = {};
				try {
					obj = JSON.parse(response);
				} catch (e) {}
				if (!obj.result) return;
				var list = obj.result.item_list;
				if (!list || !list.length) return;
				cb(list);
			}
		};
		xhr.send(fileData);
	};
};
// export const uploadDocs = async (file) => {
// 	var reader = new FileReader();
// 	reader.readAsArrayBuffer(file);
// 	reader.onload = async function (e) {
// 		var fileData = this.result;
// 		const appId = 'f014807fcf2d99d40261341b33663b7a';
// 		const secretCode = '43e34ce644fddb9eb17051c5bc683844';
// 		const url = 'https://api.textin.com/robot/v1.0/api/business_card';
// 		const headers = new Headers({
// 			'x-ti-app-id': appId,
// 			'x-ti-secret-code': secretCode,
// 		});
// 		const options = {
// 			method: 'POST',
// 			headers,
// 			body: fileData,
// 		};
// 		const response = await fetch(url, options);
// 		const result = await response.json();
// 		if (!result.result) return;
// 		const list = result.result.item_list;
// 		if (!list || !list.length) return;
// 		console.log(list);
// 	};
// };
