import './App.css';

import DetailsPage from './pages/DetailsPage';
import HomePage from './pages/HomePage';
import { toCapitalize } from './utils';
import { useState } from 'react';

function App() {
	const [activeScreen, setActiveScreen] = useState<'HOME' | 'DETAILS'>('HOME');
	const [details, setDetails] = useState(null);
	if (activeScreen === 'HOME') {
		return (
			<HomePage
				onChange={(e) => {
					const options = e
						?.filter(
							(item) =>
								item?.value?.length > 0 &&
								String(item?.key).toLowerCase() !== 'Crop_image'.toLowerCase()
						)
						.map((item) => ({
							...item,
							key: toCapitalize(item?.key),
						}));
					setDetails(options);
					setActiveScreen('DETAILS');
				}}
			/>
		);
	}
	if (activeScreen === 'DETAILS') {
		return (
			<DetailsPage
				data={details}
				onRegenerate={() => {
					setActiveScreen('HOME');
					setDetails(null);
				}}
			/>
		);
	}
}
export default App;
